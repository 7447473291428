import { Content } from "../../template/Content";
import {
  Bars3CenterLeftIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ClockIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LifebuoyIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  ShieldExclamationIcon,
  TableCellsIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import AutomationPreview from "../../../images/AutomationPreview6.png";
import AutomationPreview2 from "../../../images/AutomationPreview4.png";
import AutomationPreview3 from "../../../images/AutomationPreview5.png";
import LarianTechPlateTech from "../../../images/LarianTechPlateTech.png";
import LarianTechHotels from "../../../images/LarianTechHotels.png";

import SystemBuild from "../../../images/SystemBuild.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TagSnapshot from "../../../images/TagSnapshot.png";
import TabletMock from "../../../images/TabletMock.png";
import useAsync from "../../../hooks/useAsync";
import { sendMailAutomation, sendMailMaintenance } from "../../../api/mail";
import useRequired from "../../../hooks/useRequired";
import { Switch } from "@headlessui/react";
import ReCAPTCHA from "react-google-recaptcha";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const features = [
  {
    name: "Equipment List",
    description:
      "Our platform adopts a one-source-of-truth methodology to keep your whole team in the loop",
    icon: TableCellsIcon,
  },
  {
    name: "Incidents",
    description:
      "Our built-in ticketing system keeps track of any issues related to the lab",
    icon: ShieldExclamationIcon,
  },
  {
    name: "Service & Maintenance",
    description:
      "Set reminders for service schedules and keep the rest of your team informed on upcoming visits",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Manuals & Documents",
    description:
      "Upload manuals, quotes, POs, and other valueable documents for easy future access",
    icon: DocumentTextIcon,
  },
  {
    name: "Vendor Information",
    description:
      "Our directory keeps track of all your vendors, connects them to equipment, and logs their work",
    icon: UsersIcon,
  },
  {
    name: "Analysis",
    description:
      "Use all the saved data points to create important data-driven decisions about your lab",
    icon: ChartBarIcon,
  },
];

export default () => {
  const { pathname, hash, key } = useLocation();

  const [form, setForm] = useState({});

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          const y =
            element.getBoundingClientRect().top + window.pageYOffset - 250;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const {
    value: result,
    execute: onSendMail,
    status: isSendingMail,
    error,
  } = useAsync(sendMailAutomation, {
    immediate: false,
    showNotification: { error: "raw" },
    skipSuccessNotification: true,
  });

  const hasAll = useRequired(form, [
    "privacy",
    "name",
    "email",
    "message",
    "recaptcha",
  ]);

  console.log("form: ", form);

  return (
    <Content>
      <div className="lg:pb-0">
        <div className="mx-auto max-w-7xl px-6 py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Lab Automation
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Are you ready to automate your lab? Our team is here to help you
              figure out what exactly you need to accomplish your goals
            </p>
            <div className="mt-4 flex justify-center">
              <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
                <span className="hidden md:inline text-xs flex items-center">
                  Provided by Larian Automation
                </span>
              </p>
            </div>

            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#build"
                className="rounded-3xl bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Begin your build
              </a>
              <a
                href="#process"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Our Process <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mt-16 flow-root sm:mt-24">
            <div className="-m-30"></div>
          </div>
        </div>
      </div>

      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <div className="bg-white px-6 py-32 lg:px-8">
        {/*<div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-indigo-600">
            Automation
          </p>
          <h1
            id="features"
            className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
          >
            Our Process
          </h1>
          <p className="mt-6 text-xl leading-8">
            We strive to help labs automate their day-to-day operational
            processes so they can focus on the tasks that really matter while
            storing all their data centrally
          </p>

          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {[{ name: "Custom Design", icon: PencilIcon }].map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
              </div>*/}

        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2
                    id="process"
                    className="text-base font-semibold leading-7 text-indigo-600"
                  >
                    Automation
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Our Process
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Over the years, we've perfected our process to provide
                    efficiency and reliability from our systems and it all
                    starts with good communication
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {[
                      {
                        name: "1. Gather Information",
                        description:
                          "Our team of engineers will work directly with you to understand the problem you are trying to solve",
                      },
                      {
                        name: "2. Prototype",
                        description:
                          "Based on this information, we put together a 3D prototype for your approval",
                      },
                      {
                        name: "3a. Build",
                        description:
                          "We now start building your system in our design studio and provide you with weekly updates",
                      },
                      {
                        name: "3b. Source Equipment (if applicable)",
                        description:
                          "While we provide a lot of equipment for our system build, you may need some equipment sourced from an outside vendor",
                      },
                      {
                        name: "4. Acceptance",
                        description:
                          "Once fully complete, we invite you on-site to take a look at the system that we have built",
                      },
                      {
                        name: "5. Installation & Future Support",
                        description:
                          "Lastly, we install the system on-site and provide additional support to make sure you understand your system",
                      },
                    ].map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Previous System Builds
              </h2>
            </div>
            <ul
              role="list"
              className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
            >
              {[
                { url: AutomationPreview },
                { url: AutomationPreview3 },
                { url: AutomationPreview2 },
              ].map((person) => (
                <li key={person.url}>
                  <img
                    className=" w-full rounded-2xl object-cover"
                    src={person.url}
                    alt=""
                  />
                </li>
              ))}
            </ul>
          </div>
              </div>*/}

        <div className="bg-white py-24 md:py-32 lg:py-40">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Automation Products
              </h2>
            </div>
            <ul
              role="list"
              className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
            >
              {[
                {
                  //url: LarianTechPlateTech,
                  name: "LarianTech PlateTech",
                  role: "Plate Reorient Device",
                  bio: (
                    <a
                      className={classNames(
                        "block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm bg-indigo-600  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                      href="#build"
                    >
                      Order
                    </a>
                  ),
                },
                {
                  //url: LarianTechHotels,
                  name: "LarianTech Plate Hotel",
                  role: "Hotel",
                  bio: (
                    <a
                      className={classNames(
                        "block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm bg-indigo-600  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                      href="#build"
                    >
                      Order
                    </a>
                  ),
                },
              ].map((person) => (
                <li key={person.name}>
                  <img
                    className=" w-full rounded-2xl object-cover"
                    src={person.url}
                    alt=""
                  />
                  <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-base leading-7 text-gray-600">
                    {person.role}
                  </p>
                  <p className="mt-4 text-base leading-7 text-gray-600">
                    {person.bio}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {result?.status === "success" &&
        isSendingMail === "success" &&
        !error ? (
          <div className="text-center mb-32">
            <h1 className="text-base font-medium text-indigo-600">
              Request sent!
            </h1>
            <p className="mt-2 text-base text-gray-500">
              A customer success team member will reach out to you shortly
            </p>
          </div>
        ) : (
          <>
            <div className="mx-auto max-w-2xl text-center">
              <h2
                id="build"
                className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
              >
                Build your System
              </h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Let's start by gathering some basic information
              </p>
            </div>

            <div className="mx-auto mt-16 max-w-xl sm:mt-20">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Name{" "}
                    <span className="text-xs text-gray-500 font-light">
                      (Required)
                    </span>
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="company"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Company
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="company"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, company: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Email{" "}
                    <span className="text-xs text-gray-500 font-light">
                      (Required)
                    </span>
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="phone"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, phone: e.target.value })
                      }
                    />
                  </div>
                </div>

                <fieldset>
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900 mb-4"
                  >
                    What are you interested in?
                  </label>{" "}
                  <div className="space-y-5 ml-4">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="system"
                          aria-describedby="comments-description"
                          name="system"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) => {
                            setForm({ ...form, system: e.target.checked });
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="system"
                          className="text-gray-900 text-xs"
                        >
                          Building a custom system
                        </label>{" "}
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="platetech"
                          aria-describedby="candidates-description"
                          name="platetech"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) => {
                            setForm({
                              ...form,
                              platetech: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="platetech"
                          className="text-xs text-gray-900"
                        >
                          Purchasing a LarianTech PlateTech
                        </label>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="hotel"
                          aria-describedby="offers-description"
                          name="hotel"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) => {
                            setForm({
                              ...form,
                              hotel: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="hotel"
                          className="text-xs text-gray-900"
                        >
                          Purchasing a LarianTech Hotel
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                      onChange={(e) =>
                        setForm({ ...form, message: e.target.value })
                      }
                      placeholder="Required"
                    />
                  </div>
                </div>
                <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Switch
                      checked={form?.privacy}
                      onChange={(privacy) => setForm({ ...form, privacy })}
                      className={classNames(
                        form?.privacy ? "bg-indigo-600" : "bg-gray-200",
                        "flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                    >
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          form?.privacy ? "translate-x-3.5" : "translate-x-0",
                          "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                  <Switch.Label className="text-sm leading-6 text-gray-600">
                    By selecting this, you agree to our{" "}
                    <a href="#" className="font-semibold text-indigo-600">
                      privacy&nbsp;policy
                    </a>
                    .
                  </Switch.Label>
                </Switch.Group>
              </div>
              <div className="mt-5">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(value) => setForm({ ...form, recaptcha: value })}
                />
              </div>
              <div className="mt-10">
                <button
                  className={classNames(
                    "block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm",
                    hasAll && isSendingMail !== "pending"
                      ? " bg-indigo-600  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      : "bg-gray-300 pointer-events-none"
                  )}
                  onClick={() =>
                    onSendMail({
                      ...(form?.name ? { name: form?.name } : {}),
                      ...(form?.company ? { company: form?.company } : {}),
                      ...(form?.email ? { email: form?.email } : {}),
                      ...(form?.phone ? { phone: form?.phone } : {}),
                      ...(form?.system ? { system: form?.system } : {}),
                      ...(form?.platetech
                        ? { platetech: form?.platetech }
                        : {}),
                      ...(form?.hotel ? { hotel: form?.hotel } : {}),
                      ...(form?.message ? { message: form?.message } : {}),
                      ...(form?.recaptcha
                        ? { recaptcha: form?.recaptcha }
                        : {}),
                    })
                  }
                >
                  {isSendingMail === "pending"
                    ? "Sending Request"
                    : "Send Request"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Content>
  );
};
