import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./use-cases/";
import { Home, Login, NotFound, Single } from "./components/pages";
import { SiteSettingsProvider, ThemeProvider } from "./contexts";
import Changelog from "./components/pages/changelog";
import Contactus from "./components/pages/contactus";
import Platform from "./components/pages/platform";
import Maintenance from "./components/pages/solutions/Maintenance";
import Automation from "./components/pages/solutions/Automation";
import Environment from "./components/pages/solutions/Environment";
import Support from "./components/pages/resources/Support";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ThemeProvider>
      <SiteSettingsProvider>
        <BrowserRouter basename={""}>
          <Routes>
            <Route path={"/"} element={<Home />} />

            <Route path={"/solutions/platform"} element={<Platform />} />
            <Route
              path={"/solutions/platform#features"}
              element={<Platform />}
            />
            <Route
              path={"/solutions/platform#integrations"}
              element={<Platform />}
            />
            <Route
              path={"/solutions/platform#lab-status"}
              element={<Platform />}
            />
            <Route
              path={"/solutions/platform/changelog"}
              element={<Changelog />}
            />

            {/* Marketplace */}
            <Route path={"/marketplace"} element={<Single />} />

            {/* Solutions */}
            <Route path={"/solutions/service"} element={<Maintenance />} />
            <Route path={"/solutions/automation"} element={<Automation />} />
            <Route path={"/solutions/monitoring"} element={<Environment />} />

            {/* Resources */}
            <Route path={"/company"} element={<Support />} />
            <Route path={"/blog"} element={<Support />} />
            <Route path={"/support"} element={<Support />} />

            {/* Contact Us */}
            <Route path={"/contact-us"} element={<Contactus />} />

            {/* Contact Us */}
            <Route path={"/schedule-a-demo"} element={<Contactus />} />

            <Route
              path={"/users"}
              element={
                <PrivateRoute roles={["ROLE_ADMIN"]} minlevel={4}>
                  <Single />
                </PrivateRoute>
              }
            />
            <Route path={"/login"} element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <ToastContainer
            autoClose={2000}
            className="text-xs"
            hideProgressBar
          />
        </BrowserRouter>
      </SiteSettingsProvider>
    </ThemeProvider>
  );
}

export default App;
